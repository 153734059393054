import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";

export default function BlogPostTemplate({ data }) {
	const post = data?.wpPost;

	return (
		<Layout>
			<GatsbySeo
				title={post.title}
				language="en"
				canonical={`https://www.sarah-bennett.com/blog/${post.slug}`}
				openGraph={{
					type: "website",
					url: `https://www.sarah-bennett.com/blog/${post.slug}`,
					title: `${post.title}`,
					images: [
						{
							url: `${post.image.featuredImage.localFile.publicURL}`,
							width: 1920,
							height: 1080,
							alt: `${post.title}`,
						},
					],
				}}
			/>
			<Container>
				<Row className="mt-5">
					<Col>
						<h1>{post.title}</h1>
						<hr className="bg-primary w-100" />
					</Col>
				</Row>
				<Row className="text-center my-5">
					<Col>
						<GatsbyImage
							image={
								post.image.featuredImage.localFile.childImageSharp
									.gatsbyImageData
							}
						/>
					</Col>
				</Row>
			</Container>
			<section className="mb-6">
				<Container>
					<Row>
						<Col>
							<div
								className="blog-post-content"
								style={{ lineHeight: "2", fontSize: "1.1rem" }}
							>
								{parse(post.content)}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
}

export const blogData = graphql`
	query ($id: String!) {
		wpPost(id: { eq: $id }) {
			id
			slug
			title
			excerpt
			content
			image {
				featuredImage {
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}
	}
`;
